<template>
  <div>
    <v-card class="mt-3 pa-5">
      <v-card-title>
        <p class="display-3 ma-0 pa-0 mb-5 ">
          Reporte global de pagos en caja
        </p>
      </v-card-title>
      <v-card-subtitle>
        <p>Módulo para visualizar un reporte detallado de los ingresos en caja por diferentes métodos de pago y tipo de moneda dado un rango de fechas</p>
      </v-card-subtitle>
      <v-card-text>
        <v-alert color="black" class="white--text">Especifique un rango de fechas para el reporte de caja</v-alert>
        <v-row>
          <v-col cols="2">
            <v-text-field v-model="reportDates.startDate" label="Fecha de inicio del reporte" type="date"></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field v-model="reportDates.endDate" label="Fecha de término del reporte" type="date"></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-select @change="cleanFilter()" label="Método de pago" v-model="reportDates.paymentsMethods" :items="itemsFilterPaymentMethods" item-value="id" item-text="payment_method_name" return-object></v-select>
          </v-col>
          <v-col cols="2">
            <v-select @change="cleanFilter()" label="Moneda" v-model="reportDates.currencies" :items="itemsCurrenciesList" item-value="id" item-text="currency_iso" return-object></v-select>
          </v-col>
          <v-col cols="4">
            <v-btn x-large color="black" @click="getPaymentsSalesReport" outlined>
              <v-icon left>mdi-arrow-right</v-icon>
              Confirmar
            </v-btn>
            <v-btn :disabled="loadingExportFile" x-large color="black" @click="exportPaymentsSalesReport" class="ml-3 white--text">
              <v-icon left>mdi-file-pdf-box</v-icon>
              Exportar a PDF
            </v-btn>
          </v-col>
        </v-row>


        <div v-if="loadingExportFile">
          <v-progress-linear
              indeterminate
              color="blue"
              class="mb-5"
          ></v-progress-linear>
        </div>
        <div v-else>
          <div v-if="itemsReport">
            <v-alert class="mt-5 mb-5" color="black" outlined dismissible>
              <strong>Atención:</strong> Este reporte refleja los movimientos netos en caja, para un reporte más detallado puede consultar el reporte general de pagos en caja
            </v-alert>
            <!-- Pagos en efectivo en MXN -->
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th colspan="8" class="text-left subtitle-header">
                    <p class="pa-0 ma-0 text-h6">Pagos en efectivo en MXN</p>
                  </th>
                </tr>
                <tr>
                  <th class="text-center">Fecha y hora</th>
                  <th class="text-center">Método de pago</th>
                  <th class="text-center">Cambio moneda</th>
                  <th class="text-center">Efectivo proporcionado</th>
                  <th class="text-center">Cambio en moneda original</th>
                  <th class="text-center">Cambio calculado en MXN</th>
                  <th class="text-right">Ingreso neto en MXN</th>
                  <th class="text-right">Ingreso neto en USD</th>
                </tr>
                </thead>
                <tbody>
                <template v-if="!itemsReport.payments_cash_mxn || itemsReport.payments_cash_mxn.length === 0">
                  <tr>
                    <td colspan="8" class="text-center bg-grey">No se encontraron movimientos</td>
                  </tr>
                </template>
                <template v-else>
                  <tr v-for="(mInvoice, index) in itemsReport.payments_cash_mxn" :key="index">
                    <td>{{ formatDate(mInvoice.created_at) }}</td>
                    <td class="text-center">{{ mInvoice.payment_method.payment_method_name }}</td>
                    <td class="text-right">{{ mInvoice.change_currency.current_amount_mxn }}</td>
                    <td class="text-right">{{ mInvoice.cash_provided }}</td>
                    <td class="text-right">{{ mInvoice.cash_change_calculated }}</td>
                    <td class="text-right">{{ mInvoice.cash_change_calculated_mxn }}</td>
                    <td class="text-right">{{ mInvoice.amount_payment_converted_mxn }}</td>
                    <td class="text-right">{{ mInvoice.amount_payment }}</td>
                  </tr>
                  <tr>
                    <td colspan="6" class="text-right bg-grey"><strong>Totales:</strong></td>
                    <td class="text-right bg-grey">
                      ${{ totalAmount(itemsReport.payments_cash_mxn, 'amount_payment_converted_mxn') }}
                    </td>
                    <td class="text-right bg-grey">
                      ${{ totalAmount(itemsReport.payments_cash_mxn, 'amount_payment') }}
                    </td>
                  </tr>
                </template>
                </tbody>
              </template>
            </v-simple-table>

            <!-- Pagos en efectivo en USD -->
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th colspan="8" class="text-left subtitle-header">
                    <p class="pa-0 ma-0 text-h6">Pagos en efectivo en USD</p>
                  </th>
                </tr>
                <tr>
                  <th class="text-center">Fecha y hora</th>
                  <th class="text-center">Método de pago</th>
                  <th class="text-center">Cambio moneda</th>
                  <th class="text-center">Efectivo proporcionado</th>
                  <th class="text-center">Cambio en moneda original</th>
                  <th class="text-center">Cambio calculado en MXN</th>
                  <th class="text-right">Ingreso neto en MXN</th>
                  <th class="text-right">Ingreso neto en USD</th>
                </tr>
                </thead>
                <tbody>
                <template v-if="!itemsReport.payments_cash_usd || itemsReport.payments_cash_usd.length === 0">
                  <tr>
                    <td colspan="8" class="text-center bg-grey">No se encontraron movimientos</td>
                  </tr>
                </template>
                <template v-else>
                  <tr v-for="(mInvoice, index) in itemsReport.payments_cash_usd" :key="index">
                    <td>{{ formatDate(mInvoice.created_at) }}</td>
                    <td class="text-center">{{ mInvoice.payment_method.payment_method_name }}</td>
                    <td class="text-right">{{ mInvoice.change_currency.current_amount_mxn }}</td>
                    <td class="text-right">{{ mInvoice.cash_provided }}</td>
                    <td class="text-right">{{ mInvoice.cash_change_calculated }}</td>
                    <td class="text-right">{{ mInvoice.cash_change_calculated_mxn }}</td>
                    <td class="text-right">{{ mInvoice.amount_payment_converted_mxn }}</td>
                    <td class="text-right">{{ mInvoice.amount_payment }}</td>
                  </tr>
                  <tr>
                    <td colspan="6" class="text-right bg-grey"><strong>Totales:</strong></td>
                    <td class="text-right bg-grey">
                      ${{ totalAmount(itemsReport.payments_cash_usd, 'amount_payment_converted_mxn') }}
                    </td>
                    <td class="text-right bg-grey">
                      ${{ totalAmount(itemsReport.payments_cash_usd, 'amount_payment') }}
                    </td>
                  </tr>
                </template>
                </tbody>
              </template>
            </v-simple-table>

            <!-- Pagos en efectivo en EUR -->
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th colspan="8" class="text-left subtitle-header">
                    <p class="pa-0 ma-0 text-h6">Pagos en efectivo en EUR</p>
                  </th>
                </tr>
                <tr>
                  <th class="text-center">Fecha y hora</th>
                  <th class="text-center">Método de pago</th>
                  <th class="text-center">Cambio moneda</th>
                  <th class="text-center">Efectivo proporcionado</th>
                  <th class="text-center">Cambio en moneda original</th>
                  <th class="text-center">Cambio calculado en MXN</th>
                  <th class="text-right">Ingreso neto en MXN</th>
                  <th class="text-right">Ingreso neto en USD</th>
                </tr>
                </thead>
                <tbody>
                <template v-if="!itemsReport.payments_cash_eur || itemsReport.payments_cash_eur.length === 0">
                  <tr>
                    <td colspan="8" class="text-center bg-grey">No se encontraron movimientos</td>
                  </tr>
                </template>
                <template v-else>
                  <tr v-for="(mInvoice, index) in itemsReport.payments_cash_eur" :key="index">
                    <td>{{ formatDate(mInvoice.created_at) }}</td>
                    <td class="text-center">{{ mInvoice.payment_method.payment_method_name }}</td>
                    <td class="text-right">{{ mInvoice.change_currency.current_amount_mxn }}</td>
                    <td class="text-right">{{ mInvoice.cash_provided }}</td>
                    <td class="text-right">{{ mInvoice.cash_change_calculated }}</td>
                    <td class="text-right">{{ mInvoice.cash_change_calculated_mxn }}</td>
                    <td class="text-right">{{ mInvoice.amount_payment_converted_mxn }}</td>
                    <td class="text-right">{{ mInvoice.amount_payment }}</td>
                  </tr>
                  <tr>
                    <td colspan="6" class="text-right bg-grey"><strong>Totales:</strong></td>
                    <td class="text-right bg-grey">
                      ${{ totalAmount(itemsReport.payments_cash_eur, 'amount_payment_converted_mxn') }}
                    </td>
                    <td class="text-right bg-grey">
                      ${{ totalAmount(itemsReport.payments_cash_eur, 'amount_payment') }}
                    </td>
                  </tr>
                </template>
                </tbody>
              </template>
            </v-simple-table>

            <v-alert class="mt-5 mb-5" color="black" outlined dismissible>
              <strong>Atención:</strong> A continuación se muestra un resumen de pagos en tarjeta
            </v-alert>
            <!-- Pagos en tarjeta en MXN -->
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th colspan="6" class="text-left subtitle-header">
                    <p class="pa-0 ma-0 text-h6">Pagos en tarjeta en MXN</p>
                  </th>
                </tr>
                <tr>
                  <th class="text-center">Fecha y hora</th>
                  <th class="text-center">Método de pago</th>
                  <th class="text-center">Cambio moneda</th>
                  <th class="text-center">TPV</th>
                  <th class="text-right">Monto en moneda MXN</th>
                  <th class="text-right">Ingreso neto en MXN</th>
                </tr>
                </thead>
                <tbody>
                <template v-if="!itemsReport.payments_card_mxn || itemsReport.payments_card_mxn.length === 0">
                  <tr>
                    <td colspan="6" class="text-center bg-grey">No se encontraron movimientos</td>
                  </tr>
                </template>
                <template v-else>
                  <tr v-for="(mInvoice, index) in itemsReport.payments_card_mxn" :key="index">
                    <td>{{ formatDate(mInvoice.created_at) }}</td>
                    <td class="text-center">{{ mInvoice.payment_method.payment_method_name }}</td>
                    <td class="text-right">{{ mInvoice.change_currency.current_amount_mxn }}</td>
                    <td class="text-right">{{ mInvoice.tpv_type.tpv_type_name }}</td>
                    <td class="text-right">{{ mInvoice.amount_payment }}</td>
                    <td class="text-right">{{ mInvoice.amount_payment_converted_mxn }}</td>
                  </tr>
                  <tr>
                    <td colspan="4" class="text-right bg-grey"><strong>Totales:</strong></td>
                    <td class="text-right bg-grey">
                      ${{ totalAmount(itemsReport.payments_card_mxn, 'amount_payment') }}
                    </td>
                    <td class="text-right bg-grey">
                      ${{ totalAmount(itemsReport.payments_card_mxn, 'amount_payment_converted_mxn') }}
                    </td>
                  </tr>
                </template>
                </tbody>
              </template>
            </v-simple-table>

            <!-- Pagos en tarjeta en USD -->
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th colspan="6" class="text-left subtitle-header">
                    <p class="pa-0 ma-0 text-h6">Pagos en tarjeta en USD</p>
                  </th>
                </tr>
                <tr>
                  <th class="text-center">Fecha y hora</th>
                  <th class="text-center">Método de pago</th>
                  <th class="text-center">Cambio moneda</th>
                  <th class="text-center">TPV</th>
                  <th class="text-right">Monto en moneda USD</th>
                  <th class="text-right">Ingreso neto en MXN</th>
                </tr>
                </thead>
                <tbody>
                <template v-if="!itemsReport.payments_card_usd || itemsReport.payments_card_usd.length === 0">
                  <tr>
                    <td colspan="6" class="text-center bg-grey">No se encontraron movimientos</td>
                  </tr>
                </template>
                <template v-else>
                  <tr v-for="(mInvoice, index) in itemsReport.payments_card_usd" :key="index">
                    <td>{{ formatDate(mInvoice.created_at) }}</td>
                    <td class="text-center">{{ mInvoice.payment_method.payment_method_name }}</td>
                    <td class="text-right">{{ mInvoice.change_currency.current_amount_mxn }}</td>
                    <td class="text-right">{{ mInvoice.tpv_type.tpv_type_name }}</td>
                    <td class="text-right">{{ mInvoice.amount_payment }}</td>
                    <td class="text-right">{{ mInvoice.amount_payment_converted_mxn }}</td>
                  </tr>
                  <tr>
                    <td colspan="4" class="text-right bg-grey"><strong>Totales:</strong></td>
                    <td class="text-right bg-grey">
                      ${{ totalAmount(itemsReport.payments_card_usd, 'amount_payment') }}
                    </td>
                    <td class="text-right bg-grey">
                      ${{ totalAmount(itemsReport.payments_card_usd, 'amount_payment_converted_mxn') }}
                    </td>
                  </tr>
                </template>
                </tbody>
              </template>
            </v-simple-table>

            <!-- Pagos en tarjeta en EUR -->
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th colspan="6" class="text-left subtitle-header">
                    <p class="pa-0 ma-0 text-h6">Pagos en tarjeta en EUR</p>
                  </th>
                </tr>
                <tr>
                  <th class="text-center">Fecha y hora</th>
                  <th class="text-center">Método de pago</th>
                  <th class="text-center">Cambio moneda</th>
                  <th class="text-center">TPV</th>
                  <th class="text-right">Monto en moneda EUR</th>
                  <th class="text-right">Ingreso neto en MXN</th>
                </tr>
                </thead>
                <tbody>
                <template v-if="!itemsReport.payments_card_eur || itemsReport.payments_card_eur.length === 0">
                  <tr>
                    <td colspan="6" class="text-center bg-grey">No se encontraron movimientos</td>
                  </tr>
                </template>
                <template v-else>
                  <tr v-for="(mInvoice, index) in itemsReport.payments_card_eur" :key="index">
                    <td>{{ formatDate(mInvoice.created_at) }}</td>
                    <td class="text-center">{{ mInvoice.payment_method.payment_method_name }}</td>
                    <td class="text-right">{{ mInvoice.change_currency.current_amount_mxn }}</td>
                    <td class="text-right">{{ mInvoice.tpv_type.tpv_type_name }}</td>
                    <td class="text-right">{{ mInvoice.amount_payment }}</td>
                    <td class="text-right">{{ mInvoice.amount_payment_converted_mxn }}</td>
                  </tr>
                  <tr>
                    <td colspan="4" class="text-right bg-grey"><strong>Totales:</strong></td>
                    <td class="text-right bg-grey">
                      ${{ totalAmount(itemsReport.payments_card_eur, 'amount_payment') }}
                    </td>
                    <td class="text-right bg-grey">
                      ${{ totalAmount(itemsReport.payments_card_eur, 'amount_payment_converted_mxn') }}
                    </td>
                  </tr>
                </template>
                </tbody>
              </template>
            </v-simple-table>

            <!-- Resumen de pagos en efectivo y pagos con tarjeta -->

            <v-alert class="mt-5 mb-5" color="black" outlined dismissible>
              <strong>Atención:</strong> A continuación se muestra un resumen de pagos en efectivo y pagos con tarjeta
            </v-alert>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th colspan="2" class="text-left subtitle-header">
                    <p class="pa-0 ma-0 text-h6 blue--text">Ingresos en efectivo</p>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr class="text-left subtitle-header">
                  <td><strong>Divisa</strong></td>
                  <td><strong>Total</strong></td>
                </tr>
                <tr>
                  <td>Pesos mexicanos</td>
                  <td><strong>MXN</strong> $ {{ totalAmount(itemsReport.payments_cash_mxn, 'amount_payment') }}</td>
                </tr>
                <tr>
                  <td>Dólares</td>
                  <td><strong>USD</strong> $ {{ totalAmount(itemsReport.payments_cash_usd, 'amount_payment') }}</td>
                </tr>
                <tr>
                  <td>Euros</td>
                  <td><strong>EUR</strong> $ {{ totalAmount(itemsReport.payments_cash_eur, 'amount_payment') }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>

            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th colspan="3" class="text-left subtitle-header">
                    <p class="pa-0 ma-0 text-h6 blue--text">
                      Ingresos en efectivo
                    </p>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr class="text-left subtitle-header">
                  <td><strong>Divisa</strong></td>
                  <td><strong>Total Transacción Original</strong></td>
                  <td><strong>Total Transacción Final</strong></td>
                </tr>
                <tr>
                  <td>Transacciones en pagos con Pesos mexicanos</td>
                  <td><strong>MXN</strong> $ {{ totalAmount(itemsReport.payments_card_mxn, 'amount_payment') }}</td>
                  <td><strong>MXN</strong> $ {{ totalAmount(itemsReport.payments_card_mxn, 'amount_payment_converted_mxn') }}</td>
                </tr>
                <tr>
                  <td>Transacciones en pagos con Dólares</td>
                  <td><strong>USD</strong> $ {{ totalAmount(itemsReport.payments_card_usd, 'amount_payment') }}</td>
                  <td><strong>MXN</strong> $ {{ totalAmount(itemsReport.payments_card_usd, 'amount_payment_converted_mxn') }}</td>
                </tr>
                <tr>
                  <td>Transacciones en pagos con Euros</td>
                  <td><strong>EUR</strong> $ {{ totalAmount(itemsReport.payments_card_eur, 'amount_payment') }}</td>
                  <td><strong>MXN</strong> $ {{ totalAmount(itemsReport.payments_card_eur, 'amount_payment_converted_mxn') }}</td>
                </tr>
                <tr>
                  <td colspan="3"></td>
                </tr>
                <tr>
                  <td colspan="2" class="text-left">
                    <p class="pa-0 ma-0 text-h6 blue--text">
                      Total de ingresos con tarjetas de débito/crédito
                    </p>
                  </td>
                  <td class="text-left"><strong>MXN</strong> ${{ totalCardPayments }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>

          </div>
          <div v-else>
            Por favor especifique un filtro para continuar.
          </div>
        </div>


      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import moment from "moment/moment";

export default {
  name: "ReportPaymentsSales",
  data() {
    return {
      itemsReport: [],
      totalsBrief: null,
      itemsCurrenciesList: [],
      itemsFilterPaymentMethods: [],
      reportDates: {
        startDate: '2024-06-27',
        endDate: '2024-07-01',
        paymentsMethods: null,
        currencies: null,
      },
      loadingExportFile: false,
      paymentsItems: null,
    }
  },
  computed: {
    totalCardPayments() {
      const totalMXN = parseFloat(this.totalAmount(this.itemsReport.payments_card_mxn, 'amount_payment_converted_mxn'));
      const totalUSD = parseFloat(this.totalAmount(this.itemsReport.payments_card_usd, 'amount_payment_converted_mxn'));
      const totalEUR = parseFloat(this.totalAmount(this.itemsReport.payments_card_eur, 'amount_payment_converted_mxn'));

      const total = (isNaN(totalMXN) ? 0 : totalMXN) + (isNaN(totalUSD) ? 0 : totalUSD) + (isNaN(totalEUR) ? 0 : totalEUR);
      return total.toFixed(2);
    }
  },
  created() {
    // Establece el primer y último día del mes actual usando moment
    this.reportDates.startDate = moment().startOf('month').format('YYYY-MM-DD'); // "2024-10-01"
    this.reportDates.endDate = moment().endOf('month').format('YYYY-MM-DD'); // "2024-10-31"
  },
  mounted() {
    this.getPaymentMethodsList()
    this.getCurrenciesList()
    this.getPaymentsSalesReport()
  },
  methods: {
    formatDate(date) {
      return moment(date).format('D [de] MMMM [de] YYYY [a las] h:mm A');
    },
    totalAmount(items, field) {
      if (!items || items.length === 0) return '0.00';

      const total = items.reduce((acc, item) => {
        const value = parseFloat(item[field] || 0);
        return acc + (isNaN(value) ? 0 : value);
      }, 0);

      return total.toFixed(2);
    },
    number_format(number, decimals, dec_point, thousands_point) {

      if (number == null || !isFinite(number)) {
        throw new TypeError("number is not valid");
      }

      if (!decimals) {
        var len = number.toString().split('.').length;
        decimals = len > 1 ? len : 0;
      }

      if (!dec_point) {
        dec_point = '.';
      }

      if (!thousands_point) {
        thousands_point = ',';
      }

      number = parseFloat(number).toFixed(decimals);

      number = number.replace(".", dec_point);

      var splitNum = number.split(dec_point);
      splitNum[0] = splitNum[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_point);
      number = splitNum.join(dec_point);

      return number;
    },
    sumValuesInArray(array, keyToSum) {
      const total = array.reduce((accumulator, object) => {
        return accumulator + object[keyToSum];
      }, 0);

      return total;
    },
    cleanFilter(){
      this.paymentsItems = []
    },
    getCurrenciesList(){
      this.itemsCurrenciesList = []
      this.$http.get(`${this.$apiUrl}/currencies`)
          .then((res) => {

            this.itemsCurrenciesList = res.data.data
            this.itemsCurrenciesList = [{
              id: 0,
              currency_iso: 'Todos'
            }, ...this.itemsCurrenciesList]
          })
          .finally(() => {
            this.reportDates.currencies = (this.itemsCurrenciesList[0])
          })
    },
    getPaymentMethodsList(){
      this.itemsFilterPaymentMethods = []
      this.$http.get(`${this.$apiUrl}/payment-methods`)
          .then((res) => {

            this.itemsFilterPaymentMethods = res.data.data
            this.itemsFilterPaymentMethods = [{
              id: 0,
              payment_method_name: 'Todos'
            }, ...this.itemsFilterPaymentMethods]
          })
          .finally(() => {
           this.reportDates.paymentsMethods = (this.itemsFilterPaymentMethods[0])
          })
    },
    exportPaymentsSalesReport(){

      this.loadingExportFile = true

      const currentDate = moment().format('D-MMMM-YYYY-HH-mm'); // Ejemplo: 1-octubre-2024-12-24
      const url = `${this.$apiUrl}/reports-documents/get-report-global-payments/${this.reportDates.startDate}/${this.reportDates.endDate}`;
      const fileName = `reporte-global-caja-${currentDate}.pdf`; // Nombre del archivo con fecha y hora

      this.$http({
        url: url,
        method: 'GET',
        responseType: 'blob',
      })
          .then((response) => {
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;;
            link.click(); // Desencadena la descarga
          })
          .finally(() => {
            this.loadingExportFile = false
          })
          .catch((error) => {
            console.error('Error al descargar el PDF:', error);
          });
    },
    getPaymentsSalesReport(){
      if(
          this.reportDates.startDate.trim().length <= 0||
          this.reportDates.endDate.trim().length <= 0
      ) {
        alert('Favor de ingresar fechas válidas')
        return false
      }

      this.loadingExportFile = true
      this.$http.get(`${this.$apiUrl}/reports-manager/get-report-global-payments/${this.reportDates.startDate}/${this.reportDates.endDate}`, {
        'date-from': this.reportDates.startDate,
        'date-to': this.reportDates.endDate,
      })
          .then((res) => {
            this.itemsReport = res.data.data

            console.log(this.itemsReport)
          })
          .finally(() => {
            this.loadingExportFile = false
          })
    },
  },
}
</script>

<style scoped>
.subtitle-header {
  font-weight: bold;
}
.bg-grey {
  background-color: #f0f0f0;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
</style>