<template>
  <v-app>

    <LeftMenu></LeftMenu>

    <router-view class="pa-5 grey lighten-5" />
  </v-app>
</template>

<script>
``
import Header from "@/views/templates/LeftMenu.vue";
import LeftMenu from "@/views/templates/LeftMenu.vue";

export default {
  name: 'App',
  components: {
    LeftMenu
  },
};
</script>
