<template>
  <div>
    <v-card class="mt-3 pa-5">
      <v-card-title>
        <p class="display-3 ma-0 pa-0 mb-5 ">
          Reporte de comisiones
        </p>
      </v-card-title>
      <v-card-subtitle>
        <v-alert color="black" class="white--text">Especifique un rango de fechas para el reporte de caja</v-alert>
        <v-row>
          <v-col cols="2">
            <v-text-field v-model="reportDates.startDate" label="Fecha de inicio del reporte" type="date"></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field v-model="reportDates.endDate" label="Fecha de término del reporte" type="date"></v-text-field>
          </v-col>
          <v-col cols="2">
            <!-- Primer v-select para seleccionar múltiples agencias -->
            <v-select
                v-model="selectedAgency"
                :items="agenciesWithGuides"
                item-value="id"
                item-text="agency_business_name"
                label="Selecciona una agencia"
                multiple
                @change="onAgencyChange"
            ></v-select>
          </v-col>
          <v-col cols="2">
            <!-- Segundo v-select para seleccionar múltiples guías -->
            <v-select
                v-model="selectedGuide"
                :items="filteredGuides"
                item-value="id"
                item-text="guide_name"
                label="Selecciona un guía"
                multiple
                :disabled="selectedAgency && selectedAgency.length > 1"
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-btn x-large color="black" @click="getCommissionsReport" outlined>
              <v-icon left>mdi-arrow-right</v-icon>
              Confirmar
            </v-btn>
            <v-btn :disabled="loadingExportFile" x-large color="black" @click="exportCommissionsReport" class="ml-3 white--text">
              <v-icon left>mdi-file-pdf-box</v-icon>
              Exportar a PDF
            </v-btn>
          </v-col>
        </v-row>
      </v-card-subtitle>
      <v-card-text>
        <v-alert type="warning">
          Este reporte está únicamente disponible desde la opción de <strong>Exportar a PDF</strong>.
        </v-alert>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "ReportCommissions",
  data() {
    return {
      itemsReport: [],
      loadingExportFile: false,
      loadingResults: false,
      reportDates: {
        startDate: '',
        endDate: '',
        agencies: null,
        guides : null,
      },
      agenciesWithGuides: [],
      selectedAgency: [],
      selectedGuide: [],
      filteredGuides: []
    }
  },
  created() {
    // Establece el primer y último día del mes actual usando moment
    this.reportDates.startDate = moment().startOf('month').format('YYYY-MM-DD'); // "2024-10-01"
    this.reportDates.endDate = moment().endOf('month').format('YYYY-MM-DD'); // "2024-10-31"
  },
  mounted() {
    this.getActiveAgenciesWithGuides()
  },
  methods: {
    onAgencyChange() {
      // Si hay más de una agencia seleccionada, limpia los guías
      if (this.selectedAgency.length > 1) {
        this.selectedGuide = []; // Reinicia la selección de guías
        this.filteredGuides = [];
      } else {
        // Filtra los guías según la agencia seleccionada
        const agency = this.agenciesWithGuides.find(a => a.id === this.selectedAgency[0]);
        this.filteredGuides = agency ? agency.agency_guides : [];
      }
    },
    getActiveAgenciesWithGuides(){
      // premier-customers/get-active-agencies
      this.$http.get(`${this.$apiUrl}/premier-customers/get-active-agencies`)
          .then((res) => {
            this.agenciesWithGuides = res.data.data;
          });
    },
    getCommissionsReport(){
      if(
          this.reportDates.startDate.trim().length <= 0||
          this.reportDates.endDate.trim().length <= 0
      ) {
        alert('Favor de ingresar fechas válidas')
        return false
      }

      this.loadingResults = true
      this.$http.post(`${this.$apiUrl}/reports-manager/get-commissions-report`, {
        'date-from': this.reportDates.startDate,
        'date-to': this.reportDates.endDate,
        'selected-agencies': this.selectedAgency,
        'selected-guides': this.selectedGuide,
      })
          .then((res) => {
            this.itemsReport = res.data.data
            console.log(JSON.stringify(this.itemsReport))
          })
          .finally(() => {
            this.loadingResults = false
          })
    },
    exportCommissionsReport(){
      this.loadingExportFile = true

      const currentDate = moment().format('D-MMMM-YYYY-HH-mm'); // Ejemplo: 1-octubre-2024-12-24
      const url = `${this.$apiUrl}/reports-documents/get-commissions-report`;
      const fileName = `reporte-comisiones-${currentDate}.pdf`; // Nombre del archivo con fecha y hora

      // Parámetros a enviar por POST
      const params = {
        'date-from': this.reportDates.startDate,
        'date-to': this.reportDates.endDate,
        'selected-agencies': this.selectedAgency,
        'selected-guides': this.selectedGuide,
      };

      this.$http({
        url: url,
        method: 'POST',
        responseType: 'blob', // Para manejar el PDF en formato blob
        data: params, // Aquí enviamos los parámetros
      })
          .then((response) => {
            // Crear un blob a partir de la respuesta
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName; // Asigna el nombre de archivo
            link.click(); // Desencadena la descarga
          })
          .finally(() => {
            this.loadingExportFile = false; // Termina la carga
          })
          .catch((error) => {
            console.error('Error al descargar el PDF:', error); // Manejo de errores
          });
    },
  },
}
</script>
