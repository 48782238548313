import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import moment from 'moment'
import 'moment/locale/es'
import axios from 'axios';

// Importar Google Fonts
const link = document.createElement('link');
link.href = 'https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap';
link.rel = 'stylesheet';
document.head.appendChild(link);

import './assets/style.css';

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
Vue.prototype.$apiUrl = process.env.VUE_APP_API_URL;
Vue.prototype.$http = axios;
Vue.config.productionTip = false
Vue.prototype.moment = moment
moment.locale('es')

Vue.config.devtools = true;
new Vue({
  router,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
