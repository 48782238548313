function numeroALetras(num) {
    const unidades = ["", "un", "dos", "tres", "cuatro", "cinco", "seis", "siete", "ocho", "nueve"];
    const decenas = ["", "diez", "veinte", "treinta", "cuarenta", "cincuenta", "sesenta", "setenta", "ochenta", "noventa"];
    const centenas = ["", "ciento", "doscientos", "trescientos", "cuatrocientos", "quinientos", "seiscientos", "setecientos", "ochocientos", "novecientos"];
    const miles = ["", "mil", "dos mil", "tres mil", "cuatro mil", "cinco mil", "seis mil", "siete mil", "ocho mil", "nueve mil"];

    function getDecenasYUnidades(n) {
        if (n < 10) return unidades[n];
        if (n >= 10 && n < 20) {
            switch (n) {
                case 10: return "diez";
                case 11: return "once";
                case 12: return "doce";
                case 13: return "trece";
                case 14: return "catorce";
                case 15: return "quince";
                default: return "dieci" + unidades[n - 10];
            }
        }
        if (n >= 20 && n < 30) return n === 20 ? "veinte" : "veinti" + unidades[n - 20];
        return decenas[Math.floor(n / 10)] + (n % 10 ? " y " + unidades[n % 10] : "");
    }

    function getCentenas(n) {
        if (n === 100) return "cien";
        return centenas[Math.floor(n / 100)] + (n % 100 ? " " + getDecenasYUnidades(n % 100) : "");
    }

    function getMiles(n) {
        if (n >= 1000) {
            const milesPart = Math.floor(n / 1000);
            const resto = n % 1000;
            let milesText = milesPart === 1 ? "mil" : getCentenas(milesPart) + " mil";
            return resto > 0 ? milesText + " " + getCentenas(resto) : milesText;
        }
        return getCentenas(n);
    }

    // Convertir parte entera y decimales
    const entero = Math.floor(num);
    const centavos = Math.round((num - entero) * 100);

    // Convertir parte entera a letras
    let letras = entero === 0 ? "cero" : getMiles(entero);

    // Añadir pesos y centavos
    letras += entero === 1 ? " peso" : " pesos";
    if (centavos > 0) {
        letras += " con " + getDecenasYUnidades(centavos) + " centavos";
    } else {
        letras += " con cero centavos";
    }

    return letras + " m.n.";
}

export default numeroALetras;
