import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import es from 'vuetify/lib/locale/es'; // Importar el idioma español

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { es },  // Agregar español a los idiomas disponibles
        current: 'es',    // Configurar español como idioma por defecto
    },
});
