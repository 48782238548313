<template>
  <div>

    <v-card class="mt-3">
      <v-card-title>
        <p class="display-3 ma-0 pa-0 mb-5 ">
          Clientes
        </p>
      </v-card-title>
      <v-card-subtitle>
        <p>Módulo para administrar agencias y guías</p>
      </v-card-subtitle>
      <v-card-text>
        <v-alert color="black" outlined dismissible>
          <v-icon>mdi-information-variant-circle-outline</v-icon> Por favor administre los clientes y sus respectivas comisiones para que sean calculadas en la caja.
        </v-alert>

        <v-btn @click="openAdminAgenciesModal()" color="blue" class="mb-3 mr-3" outlined>Administrar agencias</v-btn>

        <v-btn @click="openAdminGuidesModal()" color="blue" class="mb-3" outlined>Administrar guías</v-btn>

        <v-snackbar
            v-model="snackbarMessage"
            timeout="2000"
            absolute
            color="success"
            outlined
            top
        >
          Cliente guardado correctamente
          <template v-slot:action="{ attrs }">
            <v-btn
                text
                v-bind="attrs"
                @click="snackbarMessage = false"
            >
              Cerrar
            </v-btn>
          </template>
        </v-snackbar>

        <div v-if="!loadingResults">

          <v-divider class="mt-2 mb-2"></v-divider>

          <v-alert
              type="info"
              icon="mdi-information-slab-circle-outline"
          >Aquí se pueden administrar las agencias dadas de alta</v-alert>
          <v-data-table :headers="headersAgencies" :items="itemsAgencies">
            <template v-slot:item.agency_status="{item}">
              <v-select v-model="item.agency_status" :items="itemsStatus" item-value="id" item-text="value" return-object></v-select>
            </template>
            <template v-slot:item.agency_person_in_charge_commission_amount="{item}">
              {{item.agency_person_in_charge_commission_amount}}%
            </template>
            <template v-slot:item.actions="{item, index}">
              <v-btn
                  :disabled="disableDeleteAgency(item)"
                  icon @click="deleteAgency(item, index)">
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.number_guides="{item, index}">
              <v-btn @click="openAgencyGuidesDetails(item)" text link>({{(item.agency_guides) ? item.agency_guides.length : 0}}) Administrar</v-btn>
            </template>
          </v-data-table>

<!--          <v-data-table class="mt-5" :headers="headers" :items="desserts">
            <template v-slot:item.customer_comission_type="{ item }">
              {{ getComissionTypeString(item) }}
            </template>
            <template v-slot:item.customer_amount="{ item }">
              {{ getAmountTypeString(item) }}
            </template>
            <template v-slot:item.actions="{item, index}">
              <v-btn icon @click="deleteItem(item, index)">
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
              <v-btn icon @click="showEditCustomerForm(item)">
                <v-icon>mdi-text-box-edit</v-icon>
              </v-btn>
            </template>
          </v-data-table>-->
        </div>
        <div>
          <v-progress-linear
              v-if="loadingResults"
              indeterminate
              color="blue"
              class="mb-5"
          ></v-progress-linear>
        </div>


        <v-dialog persistent max-width="800px" v-model="dialogAdminAgencies">
          <v-card>
            <v-card-title>Administrar agencias</v-card-title>
            <v-card-text>
              <v-alert type="info" outlined>Aquí se pueden crear las agencias que estarán vinculadas a los guías. Cada
                agencia tiene un responsable y una comisión única mensual.
              </v-alert>

              <v-text-field label="Nombre del negocio" v-model="agencyForm.businessName"
                            placeholder="Ej: ESTUR"></v-text-field>
              <v-text-field label="Nombre del responsable" v-model="agencyForm.personCharge"
                            placeholder="Ej: Jorge Pérez"></v-text-field>
              <v-text-field type="number" step="0.01" v-model="agencyForm.amountCommission" label="Comisión mensual"
                            placeholder="Cantidad en porcentaje"></v-text-field>

              <v-btn
                  x-large
                  v-if="!laodingSavingAgency"
                  color="black"
                  class="white--text"
                  @click="storeAgency">Crear nueva agencia</v-btn>
              <v-progress-linear
                  v-if="laodingSavingAgency"
                  indeterminate
                  color="blue"
              ></v-progress-linear>



            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="dialogAdminAgencies = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog persistent max-width="800px" v-model="dialogAdminGuides">
          <v-card>
            <v-card-title>Administrar guías</v-card-title>
            <v-card-text>


              <v-select @change="getAgencyGuides" label="Seleccione la agencia" v-model="agencySelected" item-text="agency_business_name" item-value="id" :items="itemsAgencies" return-object></v-select>

              <div v-if="loadingAgencyGuides">
                <v-progress-linear
                    indeterminate
                    color="blue"
                    class="mb-5"
                ></v-progress-linear>
              </div>
              <div v-else>

               <div v-if="agencySelected">
                 <v-alert color="black" outlined dismissible>Formulario para añadir guías de esta agencia <strong>{{agencySelected.agency_business_name}}</strong> </v-alert>
                 <div class="mb-5">

                   <v-row>
                     <v-col cols="12" lg="12" md="12" sm="12">
                       <v-text-field v-model="agencyGuideForm.guideName" label="Nombre del guía" placeholder="Ej: Juan Pérez" hide-details></v-text-field>
                     </v-col>

                     <v-col cols="12" lg="6" md="6" sm="12">
                       <v-text-field  v-model="agencyGuideForm.guide_daily_commission_amount_liquors" type="number" label="Porcentaje de comisión de licores" placeholder="Ej: 20">
                         <template v-slot:prepend>
                           <span style="font-size: 24px; line-height: 1;">%</span>
                         </template>
                       </v-text-field>

                     </v-col>
                     <v-col cols="12" lg="6" md="6" sm="12">
                       <v-text-field  v-model="agencyGuideForm.guideDailyCommissionAmount" type="number" label="Porcentaje de comisión de mercancía" placeholder="Ej: 20">
                         <template v-slot:prepend>
                           <span style="font-size: 24px; line-height: 1;">%</span>
                         </template>
                       </v-text-field>
                     </v-col>

                     <v-col cols="12" lg="6" md="6" sm="12">
                       <v-text-field prepend-icon="mdi-currency-usd" v-model="agencyGuideForm.guideCommissionVisitAmount" type="number" label="Cantidad en dinero de comisión por visita" placeholder="Ej: 100"></v-text-field>
                     </v-col>

                     <v-col cols="12" lg="6" md="6" sm="12">
                       <v-text-field prepend-icon="mdi-currency-usd" v-model="agencyGuideForm.guide_commission_visit_amount_chofer" type="number" label="Cantidad en dinero de comisión por visita para chófer (si es que aplica)" placeholder="Ej: 100"></v-text-field>
                     </v-col>

                     <v-col cols="12" lg="6" md="6" sm="12">
                       <v-text-field prepend-icon="mdi-currency-usd" v-model="agencyGuideForm.guideCommissionAnnualAmount" type="number" label="Cantidad de comisión anual" placeholder="Ej: 100"></v-text-field>
                     </v-col>

                   </v-row>

                   <v-btn @click="storeAgencyGuide" v-if="!loadingSaveFormAgencyGuide" color="black" class="white--text" x-large>Guardar guía</v-btn>


                   <v-progress-linear
                       v-else
                       indeterminate
                       color="blue"
                       class="mb-5"
                   ></v-progress-linear>
                 </div>

                 <v-divider class="pt-5 pb-2"></v-divider>

                 <v-alert color="black" dismissible outlined>Historial de guías de la agencia <strong>{{ agencySelected.agency_business_name }}</strong></v-alert>

                 <p>Listado de guías por agencia:</p>
                 <v-data-table v-if="itemsAgencyGuides && itemsAgencyGuides.length > 0" :headers="headersAgencyGuides" :items="itemsAgencyGuides">
                   <template v-slot:item.guide_daily_commission_amount="{item, index}">
                     {{ item.guide_daily_commission_amount }}%
                   </template>
                   <template v-slot:item.guide_daily_commission_amount_liquors="{item, index}">
                     {{ item.guide_daily_commission_amount_liquors }}%
                   </template>
                   <template v-slot:item.actions="{item, index}">
                     <v-btn icon @click="deleteAgencyGuide(item, index)">
                       <v-icon>mdi-delete-outline</v-icon>
                     </v-btn>
                   </template>
                   <template v-slot:item.guide_commission_visit_amount_chofer="{item, index}">
                     ${{ item.guide_commission_visit_amount_chofer }}
                   </template>
                   <template v-slot:item.guide_commission_visit_amount="{item, index}">
                     ${{ item.guide_commission_visit_amount }}
                   </template>
                   <template v-slot:item.guide_commission_annual_amount="{item, index}">
                     {{ item.guide_commission_annual_amount }}%
                   </template>
                   <template v-slot:item.guide_barcode="{item, index}">
                     <a  target="_blank" :href="getQrCodeGuideUrl(item)">
                       <div class="pt-3 pb-3" v-html="item.guide_barcode_50"></div>
                     </a>
                   </template>
                 </v-data-table>
                 <div v-else>
                   <p>No se encontraron guías para esta agencia.</p>
                 </div>

                 <hr>
               </div>

              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="dialogAdminGuides = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog persistent max-width="800px" v-model="dialogCustomerComission">
          <v-card>
            <v-card-title>Agregar nuevo cliente e información de comisión</v-card-title>
            <v-card-text>
              <v-alert type="info">A continuación ingrese los datos del cliente, guía, agencia o cualquiera que fuera el
                contacto junto con los datos de su comisión
              </v-alert>


              <v-radio-group messages="Especifique qué tipo de comisión tiene este cliente" row
                             v-model="customerComissionForm.customerComissionType">
                <v-radio class="mr-5" :value="1" label="Cantidad $"></v-radio>
                <v-radio class="mr-5" :value="2" label="Porcentaje %"></v-radio>
              </v-radio-group>

              <v-text-field v-model="customerComissionForm.customerAmount" type="number" label="Monto de la comisión"
                            step="0.01"></v-text-field>

              <v-text-field v-model="customerComissionForm.customerName" type="text" label="Nombre del cliente"
                            placeholder="Ej: Turismo Estrella Blanca"></v-text-field>

              <v-textarea v-model="customerComissionForm.customerDescription"
                          placeholder="Detalles del cliente (opcional)" :rows="3"></v-textarea>

              <p v-if="customerComissionForm.customerAmount > 0"><strong>Nota importante: </strong> Este cliente va a
                recibir una comisión {{ getComissionDescription() }} </p>


              <v-progress-linear
                  v-if="loadingStoringCustomer"
                  indeterminate
                  color="blue"
                  class="mb-5"
              ></v-progress-linear>

              <v-btn v-if="!currentCustomer" :disabled="loadingStoringCustomer" color="info" @click="storeCustomer">
                Guardar
              </v-btn>
              <v-btn v-else :disabled="loadingStoringCustomer" color="info" @click="updateCustomer">Guardar cambios
              </v-btn>
            </v-card-text>
            <v-card-actions>

              <v-spacer></v-spacer>
              <v-btn text @click="dialogCustomerComission = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  name: 'CustomersHome',
  data() {
    return {
      loadingSaveFormAgencyGuide: false,
      headersAgencyGuides: [
        {
          value: 'id',
          text: '#'
        },
        {
          value: 'guide_name',
          text: 'Nombre'
        },
        {
          value: 'guide_daily_commission_amount',
          text: 'Comisión Mercancía'
        },
        {
          value: 'guide_daily_commission_amount_liquors',
          text: 'Comisión Licor'
        },
        {
          value: 'guide_commission_visit_amount_chofer',
          text: 'Comisión Chófer'
        },
        {
          value: 'guide_commission_visit_amount',
          text: 'Comisión Visita'
        },
        {
          value: 'guide_commission_annual_amount',
          text: 'Comisión Anual'
        },
        {
          value: 'unique_code_business',
          text: 'Código'
        },
        {
          value: 'guide_barcode',
          text: 'QR'
        },
        {
          value: 'actions',
          text: 'Acciones'
        }
      ],
      itemsAgencyGuides: null,
      loadingAgencyGuides: false,
      itemsStatus: [
        {
          id: 1,
          value: 'Activo',
        },
        {
          id: 2,
          value: 'Inactivo',
        },
        /*{
          id: 3,
          value: 'Otro',
        }*/
      ],
      laodingSavingAgency: false,
      headersAgencies: [
        {
          value: 'id',
          text: '#'
        },
        {
          value: 'agency_business_name',
          text: 'Nombre de la agencia'
        },
        {
          value: 'agency_person_in_charge_name',
          text: 'Responsable'
        },
        {
          value: 'agency_person_in_charge_commission_amount',
          text: 'Comisión mensual'
        },
        {
          value: 'number_guides',
          text: 'No. Guías'
        },
        {
          value: 'agency_status',
          text: 'Estatus'
        },
        {
          value: 'actions',
          text: 'Acciones'
        },
      ],
      itemsAgencies: [],
      loadingResults: false,
      snackbarMessage: false,
      loadingStoringCustomer: false,
      desserts: [],
      currentCustomer: null,
      customerComissionForm: {
        customerName: '',
        customerDescription: '',
        customerComissionType: 1,
        customerAmount: '',
        customerStatus: '',
      },
      customer_comission_type: 1,
      dialogCustomerComission: false,
      /*headers: [
        {
          value: 'id',
          text: '#'
        },
        {
          value: 'customer_name',
          text: 'Nombre del cliente'
        },
        {
          value: 'customer_comission_type',
          text: 'Tipo de comisión'
        },
        {
          value: 'customer_amount',
          text: 'Monto de comisión'
        },
        {
          value: 'actions',
          text: 'Acciones'
        },
      ],*/
      dialogAdminAgencies: false,
      dialogAdminGuides: false,
      agencyForm: {
        businessName: '',
        personCharge: '',
        amountCommission: 0,
      },
      currentAgency:null,
      agencySelected: null,
      agencyGuideForm: {
        agencyId: 0,
        guideName: '',
        guide_daily_commission_amount_liquors: 10,
        guideDailyCommissionAmount: 20,
        guideCommissionVisitAmount: 100,
        guide_commission_visit_amount_chofer: 100,
        guideCommissionAnnualAmount: 2,
      }
    }
  },
  mounted() {
    // this.getComissionCustomers()

    this.getActiveAgencies()
  },
  methods: {
    getQrCodeGuideUrl(itemGuide){
      return `${this.$apiUrl}/premier-customers/generate-guide-qrcode/${itemGuide.id}`
    },
    disableDeleteAgency(item){
      if(item.agency_guides && item.agency_guides.length > 0) {
        return true
      }
      return false
    },
    getAgencyGuides(){
      console.log(this.agencySelected)
      this.loadingAgencyGuides = true
      this.$http.get(`${this.$apiUrl}/premier-customers/get-guides/${this.agencySelected.id}`)
          .then((res) => {
            this.itemsAgencyGuides = res.data.data
          }).finally(() => {
            this.loadingAgencyGuides = false
      })
    },
    deleteAgencyGuide(itemAgencyGuide, position){
      let confirmDelete = confirm('¿Confirma eliminar el #' + itemAgencyGuide.id + '?')
      if(confirmDelete) {
        this.laodingSavingAgency = true
        this.$http.delete(`${this.$apiUrl}/premier-customers/delete-agency-guide/${itemAgencyGuide.id}`).then((res) => {
          this.itemsAgencyGuides.splice(position, 1)
        }).finally(() => {
          this.laodingSavingAgency = false
        })
      }
    },
    deleteAgency(itemAgency, position){
      let confirmDelete = confirm('¿Confirma eliminar el #' + itemAgency.id + '?')
      if(confirmDelete) {
        this.laodingSavingAgency = true
        this.$http.delete(`${this.$apiUrl}/premier-customers/delete-agency/${itemAgency.id}`).then((res) => {
          this.itemsAgencies.splice(position, 1)
        }).finally(() => {
          this.laodingSavingAgency = false
        })
      }
    },
    updateAgency(){
    },
    getActiveAgencies() {
      this.$http.get(`${this.$apiUrl}/premier-customers/get-active-agencies?from-admin=1`)
          .then((res) => {
            this.itemsAgencies = res.data.data
            // default agency
            this.agencySelected = this.itemsAgencies[0]
          })
    },
    storeAgencyGuide() {
      if (
          !this.agencySelected||
          this.agencyGuideForm.guideName.trim().length <= 0||
          parseFloat(this.agencyGuideForm.guideDailyCommissionAmount) <= 0||
          parseFloat(this.agencyGuideForm.guideCommissionVisitAmount) <= 0||
          parseFloat(this.agencyGuideForm.guideCommissionAnnualAmount) <= 0
      ) {
        alert('Todos los campos son obligatorios')
        return false
      }
      this.loadingSaveFormAgencyGuide = true
      this.$http.post(`${this.$apiUrl}/premier-customers/store-agency-guide`, {
        agency_id: this.agencySelected.id,
        guide_daily_commission_amount_liquors: this.agencyGuideForm.guide_daily_commission_amount_liquors,
        guide_commission_visit_amount_chofer: this.agencyGuideForm.guide_commission_visit_amount_chofer,
        guide_name: this.agencyGuideForm.guideName,
        guide_daily_commission_amount: this.agencyGuideForm.guideDailyCommissionAmount,
        guide_commission_visit_amount: this.agencyGuideForm.guideCommissionVisitAmount,
        guide_commission_annual_amount: this.agencyGuideForm.guideCommissionAnnualAmount,
      }).then((res) => {
        let mData = res.data.data
        this.itemsAgencyGuides.push(res.data.data)
        this.getActiveAgencies()
      }).finally(() => {
        this.loadingSaveFormAgencyGuide = false
      })
    },
    storeAgency() {
      if (
          this.agencyForm.businessName.trim().length <= 0 ||
          this.agencyForm.personCharge.trim().length <= 0 ||
          parseInt(this.agencyForm.amountCommission) <= 0
      ) {
        alert('Todos los campos son obligatorios')
        return false
      }
      this.laodingSavingAgency = true
      this.$http.post(`${this.$apiUrl}/premier-customers/store-agency`, {
        agency_business_name: this.agencyForm.businessName,
        agency_person_in_charge_name: this.agencyForm.personCharge,
        agency_person_in_charge_commission_amount: this.agencyForm.amountCommission,
      }).then((res) => {
        this.itemsAgencies.push(res.data.data)

        this.agencyForm.businessName = ''
        this.agencyForm.personCharge = ''
        this.agencyForm.amountCommission = 0
      }).finally(() => {
        this.laodingSavingAgency = false
      })
    },
    openAdminAgenciesModal() {
      this.dialogAdminAgencies = true
    },
    openAgencyGuidesDetails(itemAgency){
      this.dialogAdminGuides = true
      this.agencySelected = itemAgency

      this.getAgencyGuides()
    },
    openAdminGuidesModal() {
      this.dialogAdminGuides = true


      this.agencySelected = null
      this.loadingSaveFormAgencyGuide = false
      this.loadingAgencyGuides = false

      this.agencyGuideForm.agencyId = '';
      this.agencyGuideForm.guideName = '';
      this.agencyGuideForm.guideDailyCommissionAmount = 20;
      this.agencyGuideForm.guideCommissionVisitAmount = 100;
      this.agencyGuideForm.guideCommissionAnnualAmount = 2;
      this.agencyGuideForm.guide_daily_commission_amount_liquors = 10
      this.agencyGuideForm.guide_commission_visit_amount_chofer = 100
    },
    getAmountTypeString(item) {
      return (parseInt(item.customer_comission_type) === 1) ? `$${item.customer_amount}` : `${item.customer_amount}%`
    },
    getComissionTypeString(item) {
      return (parseInt(item.customer_comission_type) === 1) ? 'Cantidad' : 'Porcentaje'
    },
    getComissionDescription() {

      if (this.customerComissionForm.customerAmount <= 0) {
        return ''
      }

      if (parseInt(this.customerComissionForm.customerComissionType) === 1) {
        // porcentaje
        return `de $${this.customerComissionForm.customerAmount} aplicado a la venta total de la nota (sin IVA)`
      }
      // cantidad
      return `del ${this.customerComissionForm.customerAmount}% aplicado a la venta total de la nota (sin IVA)`

    },
    showAddCustomerForm() {
      this.dialogCustomerComission = true
      this.customerComissionForm = {
        customerName: '',
        customerDescription: '',
        customerComissionType: 1,
        customerAmount: '',
      }
    },
    showEditCustomerForm(item) {
      this.currentCustomer = item
      this.dialogCustomerComission = true
      this.customerComissionForm = {
        customerName: this.currentCustomer.customer_name,
        customerDescription: this.currentCustomer.customer_description,
        customerComissionType: this.currentCustomer.customer_comission_type,
        customerAmount: this.currentCustomer.customer_amount,
      }
    },
    getComissionCustomers() {
      /*
      this.loadingResults = true
      this.$http.get(`${this.$apiUrl}/customers-comissions`)
          .then((res) => {
            this.desserts = res.data.data
          }).finally(() => {
        this.loadingResults = false
      })*/
    },
    deleteItem(item) {
      let confirmDelete = confirm('¿Confirma eliminar el registro con #' + item.id)
      if (confirmDelete) {
        this.loadingResults = true
        /*this.$http.delete(`${this.$apiUrl}/customers-comissions/${item.id}`)
            .then((res) => {
            }). finally(() => {
          this.getComissionCustomers()
        })*/
      }
    },
    updateCustomer() {
      if (
          this.customerComissionForm.customerName.trim().length <= 0 ||
          this.customerComissionForm.customerDescription.trim().length <= 0 ||
          this.customerComissionForm.customerAmount <= 0
      ) {
        alert('Por favor ingrese todos los campos')
        return false
      }
      this.loadingStoringCustomer = true

      /*this.$http.patch(`${this.$apiUrl}/customers-comissions/${this.currentCustomer.id}`,
          {
            customer_name: this.customerComissionForm.customerName,
            customer_description: this.customerComissionForm.customerDescription,
            customer_comission_type: this.customerComissionForm.customerComissionType,
            customer_amount: this.customerComissionForm.customerAmount,
          })
          .then((res) => {
            this.snackbarMessage = true
            this.dialogCustomerComission = false
            this.getComissionCustomers()
            this.currentCustomer = null
          }). finally(() => {
        this.loadingStoringCustomer = false
      })*/
    },
    storeCustomer() {
      if (
          this.customerComissionForm.customerName.trim().length <= 0 ||
          this.customerComissionForm.customerDescription.trim().length <= 0 ||
          this.customerComissionForm.customerAmount <= 0
      ) {
        alert('Por favor ingrese todos los campos')
        return false
      }
      this.loadingStoringCustomer = true

      /*this.$http.postForm(`${this.$apiUrl}/customers-comissions`,
          {
            customer_name: this.customerComissionForm.customerName,
            customer_description: this.customerComissionForm.customerDescription,
            customer_comission_type: this.customerComissionForm.customerComissionType,
            customer_amount: this.customerComissionForm.customerAmount,
          })
          .then((res) => {
            this.snackbarMessage = true
            this.dialogCustomerComission = false
            this.getComissionCustomers()
          }). finally(() => {
        this.loadingStoringCustomer = false

      })*/
    }
  },

}
</script>