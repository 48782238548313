<template>
  <div>
    <ShoppingCart></ShoppingCart>
  </div>
</template>

<script>
import ShoppingCart from "@/views/views/shoppingcart/ShoppingCart.vue";

export default {
  name: 'Home',
  components: {ShoppingCart},
}
</script>
